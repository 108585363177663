import httpUtil from '../utils/httpUtil'

/**
 * 创建申请数据
 * @param params
 * @returns {Promise<R>}
 */
export const addWxMerchant = params => httpUtil.post("wx/merchant/add", params);

/**
 * 保存主体信息
 * @param params
 * @returns {Promise<R>}
 */
export const addSubjectInfo = params => httpUtil.post("wx/merchant/addSubjectInfo", params);

/**
 * 录入经营信息
 * @param params
 * @returns {Promise<R>}
 */
export const addBusinessInfo = params => httpUtil.post("wx/merchant/addBusinessInfo", params);

/**
 * 录入结算账户信息
 * @param params
 * @returns {Promise<R>}
 */
export const addBankAccountInfo = params => httpUtil.post("wx/merchant/addBankAccountInfo", params);
/**
 * 保存超级管理员信息
 * @param params
 * @returns {Promise<R>}
 */
export const addContactInfo = params => httpUtil.post("wx/merchant/addContactInfo", params);
/**
 * 保存补充信息
 * @param params
 * @returns {Promise<R>}
 */
export const addAdditionInfo = params => httpUtil.post("wx/merchant/addAdditionInfo", params);
/**
 *保存行业资质信息
 * @param params
 * @returns {Promise<R>}
 */
export const addSettlementInfo = params => httpUtil.post("wx/merchant/addSettlementInfo", params);

/**
 * 提交
 * @param params
 * @returns {Promise<R>}
 */
export const submitToWx = params => httpUtil.post("wx/merchant/submitToWx", params);
/**
 * 获取申请信息
 * @param params
 * @returns {Promise<R>}
 */
export const getWxMerchant = params => httpUtil.get("wx/merchant/getWxMerchant", params);

export const getIndustryInfo = params => httpUtil.get("wx/merchant/getIndustryInfo", params);
/**
 * 获取所有省份
 * @param params
 * @returns {Promise<R>}
 */
export const getProvince = params => httpUtil.get("wx/merchant/getProvince", params);
/**
 * 获取城市列表
 * @param params
 * @returns {Promise<R>}
 */
export const getCity = params => httpUtil.get("wx/merchant/getCity", params);
/**
 * 获取银行列表  获取主银行传offset 0 limit 17
 * @param params
 * @returns {Promise<R>}
 */
export const getBank = params => httpUtil.get("wx/merchant/getBank", params);
/**
 * 获取银行支行信息
 * @param params
 * @returns {Promise<R>}
 */
export const getBranchBank = params => httpUtil.get("wx/merchant/getBranchBank", params);

/**
 * 获取商户列表
 * @param params
 * @returns {Promise<R>}
 */
export const getWxMerchantInfo = params => httpUtil.get("wx/merchant/getWxMerchantInfo", params);
/**
 * 是否有商户申请信息
 * @param params
 * @returns {Promise<R>}
 */
export const isApplyInfo = params => httpUtil.get("wx/merchant/isApplyInfo", params);

/**
 * 获取主体信息
 * @param params
 * @returns {Promise<R>}
 */
export const getSubjectInfo = params => httpUtil.get("wx/merchant/getSubjectInfo", params);
/**
 * 获取经营信息
 * @param params
 * @returns {Promise<R>}
 */
export const getBusinessInfo = params => httpUtil.get("wx/merchant/getBusinessInfo", params);

export const getSettlementInfo = params => httpUtil.get("wx/merchant/getSettlementInfo", params);

export const getBankAccountInfo = params => httpUtil.get("wx/merchant/getBankAccountInfo", params);

export const getContactInfo = params => httpUtil.get("wx/merchant/getContactInfo", params);





